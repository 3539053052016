import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Link from 'next/link'
import { useWallet } from '@solana/wallet-adapter-react'

export default function PsaAlertBanner() {
  const [showing, setShowing] = useState(false)
  const wallet = useWallet()

  useEffect(() => {
    // initially show for 1 week until Aug 12th 2022, midnight GMT 
    const showUntil = 1660348799000 
    if (showUntil - Date.now() > 0) {
      // only show if Slope Wallet was connected
      setShowing(wallet?.wallet?.adapter?.name === "Slope")
    }
  }, [wallet])

  if (!showing) {
    return <></>
  }

  return (
    <Container className="pt-3">
      <div className="d-flex alert alert-warning">
        <div>
          <i className="fa-thin fa-warning me-2 fa-lg" /> Warning: A recent exploit regarding seed phrases created or imported into Slope Wallet has been detected. Please follow steps in Slope&apos;s{' '} 
          <Link href="https://slope-finance.medium.com/slopes-official-statement-regarding-the-breach-b964e70af0d6">official statement</Link> to prevent loss of funds.
        </div>
      </div>
    </Container>
  )
}
