import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { HedgeProgram } from '../../on-chain/utils/HedgeConstants'


export default function RecentLiquidations() {
  const [showing, setShowing] = useState(false)
  const wallet = useWallet()
  const { connection } = useConnection()
  const program = HedgeProgram(connection, wallet)

  useEffect(() => {
    program.provider.connection.getLatestBlockhash().then((blockhash) => {
      console.log('blockhash', blockhash)
      program.provider.connection.getSlot().then((slot) => {
        program.provider.connection.getBlockTime(slot).then((blockTime) => {
          console.log('recent block time:', blockTime)
          const delta = Date.now() / 1000 - blockTime
          if (Math.abs(delta) > 60) {
            console.log('Time delta', delta)
            setShowing(true)
          }
        })
      })
    })
  }, [])

  if (!showing) {
    return <></>
  }

  return (
    <Container className="pt-3">
      <div className="d-flex alert alert-warning">
        <div>
          <i className="fa-thin fa-warning me-2 fa-lg" /> Warning: The Solana Cluster time is more
          than one minute out of sync. Some UI elements might not correctly show time based
          calculations. The Solana cluster will slowly adjust to the correct time.
        </div>
      </div>
    </Container>
  )
}
